import iMac from '../assets/storeImages/iMac.png'
import iPadAir from '../assets/storeImages/iPadAir.png'
import iPhone12Pro from '../assets/storeImages/iPhone12Pro.png'
import AirPodsMax from '../assets/storeImages/AirPodsMax.png'
import AppleTV from '../assets/storeImages/AppleTV.png'
import AirTag from '../assets/storeImages/AirTag.png'
import MXKeys from '../assets/storeImages/MXKeys.png'
import MXMaster3 from '../assets/storeImages/MXMaster3.png'
import HomePodMini from '../assets/storeImages/HomePodMini.png'

export const storeItems = [
    {
        image: iMac,
        link: 'https://amzn.to/2TE1sxO'
    },
    {  
        image: iPadAir,
        link: 'https://amzn.to/2V8RdC5'
    },
    {  
        image: iPhone12Pro,
        link: 'https://amzn.to/3ypLcPV'
    },
    {  
        image: AirPodsMax,
        link: 'https://amzn.to/2TxYn2z'
    },
    {  
        image: AppleTV,
        link: 'https://amzn.to/3dOgOXH'
    },
    {  
        image: AirTag,
        link: 'https://amzn.to/2VeRpjm'
    },
    {  
        image: MXKeys,
        link: 'https://amzn.to/3qQL89b'
    },
    {  
        image: MXMaster3,
        link: 'https://amzn.to/3AIelb9'
    },
    {  
        image: HomePodMini,
        link: 'https://www.apple.com/shop/buy-homepod/homepod-mini'
    }
]
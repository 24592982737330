import cineVista from '../assets/cases/CineVistaCover.png'
import acolyte from '../assets/cases/AcolyteCover.png'
import allot from '../assets/cases/AllotCover.png'

export const caseStudies = [
    {
        title: 'Cine Vista',
        tag: 'Movie Theater App',
        description: 'Designing a ticket ordering flow from selection to seat reservation to checkout and payment',
        coverImage: cineVista
    },
    {
        title: 'Acolyte',
        tag: 'Order Dispatch System',
        description: 'Real-time multichannel order updates for an on- demand delivery service',
        coverImage: acolyte
    },
    {
        title: 'Allot',
        tag: 'Financial Journaling App',
        description: 'Designing a privacy-oriented financial journal with an emphasis on expense management',
        coverImage: allot
    }
]